
import React from 'react';
import './InstallationPrompt.css'; // Ensure your styles are imported
import { IoShareOutline } from "react-icons/io5";
import { CiSquarePlus } from "react-icons/ci";
import { useTranslation } from 'react-i18next';

const InstallationPrompt = () => {
  const { t } = useTranslation();
  return (
    <div className="installation-prompt">
      <h1>{t("Install Our App")}</h1>
      <p>{t("To use this app, you need to add it to your home screen")}:</p>
      
      {/* App Icon */}
      <div className="app-icon">
        <img src="/icon512_maskable.png" alt="App Icon" />
      </div>

      <ol>
        <li>{t("Open Safari and visit our website")}.</li>
        <li>{t("Tap the")} <strong><IoShareOutline size='20px' color='blue'/></strong> {t("icon at the bottom of the screen")}.</li>
        <li>{t("Scroll down and tap")} <strong>{t("Add to Home Screen")}</strong> <CiSquarePlus size='20px' />.</li>
        <li>{t("Tap")} <strong>{t("Add")}</strong> {t("on the top-right corner")}.</li>
      </ol>
      {/* <img src="instructions.png" alt="Instructions to add to home screen" /> */}
      <p>{t("Once added, please open the app from your home screen to continue using it")}.</p>
    </div>
  );
};

export default InstallationPrompt;
