import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthPostRequest, AuthDeleteRequest } from '../Backend/APIMiddleware';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const HoursPopupComponent = ({ show, handleClose, defaultHours, specialHours, saveHours, addSpecialHour, editSpecialHour, deleteSpecialHour }) => {
  const [hours, setHours] = useState(defaultHours);
  const [specialHoursList, setSpecialHoursList] = useState(specialHours);
  const [editingDay, setEditingDay] = useState(null);
  const [savingDay, setSavingDay] = useState(null); // Track the saving state for each day
  const { t } = useTranslation();
  const [newSpecialHour, setNewSpecialHour] = useState({ date: null, open: '', close: '', closed: false });
  const [showSpecialHoursModal, setShowSpecialHoursModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState({}); // Track deletion state for each special hour

  useEffect(() => {
    setHours(defaultHours);
    setSpecialHoursList(specialHours);
  }, [defaultHours, specialHours]);

  const handleSave = async (day) => {
    setSavingDay(day);
    const dayName = day.toUpperCase();
    const hourData = hours[day];
    const payload = {
      day: dayName,
      closed: hourData.closed,
      hours: hourData.closed ? ["Closed"] : [`${hourData.open} - ${hourData.close}`]
    };

    try {
      const response = await AuthPostRequest('/v1/public/hours', JSON.stringify(payload));
      if (response.status === 200) {
        saveHours(day, hourData);
        setEditingDay(null);
      } else {
        console.error('Failed to save default hours');
      }
    } catch (error) {
      console.error('Error saving default hours:', error);
    } finally {
      setSavingDay(null);
    }
  };

  const handleToggleChange = (day) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        closed: !prevHours[day].closed,
        open: !prevHours[day].closed ? null : prevHours[day].open,
        close: !prevHours[day].closed ? null : prevHours[day].close
      }
    }));
  };

  const handleTimeChange = (day, field, value) => {
    setHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [field]: value,
      }
    }));
  };

  const handleDateChange = (date) => {
    setNewSpecialHour({ ...newSpecialHour, date });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSpecialHour({ ...newSpecialHour, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSaveSpecialHour = async () => {
    setSaving(true);
    try {
      const formattedDate = newSpecialHour.date.toISOString().split('T')[0]; // format date to yyyy-mm-dd
      const specialHourData = {
        date: formattedDate,
        hours: newSpecialHour.closed ? ["Closed"] : [`${newSpecialHour.open} - ${newSpecialHour.close}`],
        closed: newSpecialHour.closed
      };
      const response = await AuthPostRequest('/v1/public/hours/special-hours-list', JSON.stringify(specialHourData));
      if (response.status === 200) {
        // Update the specialHoursList with the new or updated special hour
        const updatedSpecialHoursList = specialHoursList.map((hour) =>
          hour.date === formattedDate ? specialHourData : hour
        );
        if (!specialHoursList.some((hour) => hour.date === formattedDate)) {
          updatedSpecialHoursList.push(specialHourData);
        }
        setSpecialHoursList(updatedSpecialHoursList);
        setShowSpecialHoursModal(false); // Close the modal
      } else {
        console.error('Failed to save special hours');
      }
    } catch (error) {
      console.error('Error saving special hours:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteSpecialHour = async (date) => {
    setDeleting((prev) => ({ ...prev, [date]: true }));
    try {
      const response = await AuthDeleteRequest(`/v1/public/hours/special-hours-list?date=${date}`);
      if (response.status === 200) {
        const updatedSpecialHoursList = specialHoursList.filter((hour) => hour.date !== date);
        setSpecialHoursList(updatedSpecialHoursList);
      } else {
        console.error('Failed to delete special hour');
      }
    } catch (error) {
      console.error('Error deleting special hour:', error);
    } finally {
      setDeleting((prev) => ({ ...prev, [date]: false }));
    }
  };

  const toggleCloseSpecialHours = () => {
    setShowSpecialHoursModal(false);
    setNewSpecialHour({ date: null, open: '', close: '', closed: false });
  };

  return (
    <>
      {!showSpecialHoursModal && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("Edit Business Hours")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("Day")}</th>
                  <th>{t("Closed")}</th>
                  <th>{t("Open")}</th>
                  <th>{t("Close")}</th>
                  <th>{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(hours).map(day => (
                  <tr key={day}>
                    <td>{t(day)}</td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        label={t("Closed")}
                        checked={hours[day].closed}
                        onChange={() => handleToggleChange(day)}
                      />
                    </td>
                    <td>
                      {!hours[day].closed && (
                        <Form.Control
                          type="time"
                          value={hours[day].open || ''}
                          onChange={(e) => handleTimeChange(day, 'open', e.target.value)}
                        />
                      )}
                    </td>
                    <td>
                      {!hours[day].closed && (
                        <Form.Control
                          type="time"
                          value={hours[day].close || ''}
                          onChange={(e) => handleTimeChange(day, 'close', e.target.value)}
                        />
                      )}
                    </td>
                    <td>
                      <Button variant="primary" onClick={() => handleSave(day)} disabled={savingDay === day}>
                        {savingDay === day ? <Spinner animation="border" size="sm" /> : t("Save")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h5>{t("Special Hours")}</h5>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("Date")}</th>
                  <th>{t("Open")}</th>
                  <th>{t("Close")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {specialHoursList.map((specialHour, index) => (
                  <tr key={index}>
                    <td>{specialHour.date}</td>
                    <td>{specialHour.closed ? t("Closed") : specialHour.open}</td>
                    <td>{specialHour.closed ? "" : specialHour.close}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteSpecialHour(specialHour.date)}
                        disabled={deleting[specialHour.date]}
                      >
                        {deleting[specialHour.date] ? <Spinner animation="border" size="sm" /> : t("Delete")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="success" onClick={() => setShowSpecialHoursModal(true)}>
              {t("Add Special Hour")}
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal show={showSpecialHoursModal} onHide={toggleCloseSpecialHours}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add Special Hours")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{t("Date")}</Form.Label>
                  <DatePicker
                    selected={newSpecialHour.date}
                    onChange={handleDateChange}
                    minDate={new Date()} // Only allow future dates
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label={t("Closed")}
                    name="closed"
                    checked={newSpecialHour.closed}
                    onChange={handleInputChange}
                    className="mt-4"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{t("Open Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="open"
                    value={newSpecialHour.open}
                    onChange={handleInputChange}
                    disabled={newSpecialHour.closed}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{t("Close Time")}</Form.Label>
                  <Form.Control
                    type="time"
                    name="close"
                    value={newSpecialHour.close}
                    onChange={handleInputChange}
                    disabled={newSpecialHour.closed}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleCloseSpecialHours}>{t("Close")}</Button>
          <Button variant="primary" onClick={handleSaveSpecialHour} disabled={!newSpecialHour.date || (!newSpecialHour.closed && (!newSpecialHour.open || !newSpecialHour.close))}>
            {saving ? <Spinner animation="border" size="sm" /> : t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HoursPopupComponent;
