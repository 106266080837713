import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';

const ServiceListModal = ({ show, handleClose, services, addService, updateService, deleteService }) => {
    const { t } = useTranslation();

    const [serviceList, setServiceList] = useState(services);
    const [newServiceName, setNewServiceName] = useState('');
    const [newServiceDescription, setNewServiceDescription] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editingServiceName, setEditingServiceName] = useState('');
    const [editingServiceDescription, setEditingServiceDescription] = useState('');

    useEffect(() => {
        setServiceList(services);
    }, [services]);

    const handleAddService = () => {
        if (newServiceName.trim() === '' || newServiceDescription.trim() === '') return;
        addService(newServiceName, newServiceDescription);
        setNewServiceName('');
        setNewServiceDescription('');
    };

    const handleEditService = (index) => {
        setEditingIndex(index);
        setEditingServiceName(serviceList[index].serviceName);
        setEditingServiceDescription(serviceList[index].serviceDescription);
    };

    const handleUpdateService = () => {
        if (editingServiceName.trim() === '' || editingServiceDescription.trim() === '') return;
        const list = updateService(editingIndex, editingServiceName, editingServiceDescription);
        if(list.length > 0){
            console.log(list)
            setServiceList(list)
        }
        setEditingIndex(null);
        setEditingServiceName('');
        setEditingServiceDescription('');
    };

    const handleDeleteService = (index) => {
        if(serviceList.length === 1){
            alert("You must have at least one service.")
        }
        else{
            deleteService(index);
        }
        
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t("Edit Services")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="newServiceName">
                        <Form.Label>{t("Service Name")}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newServiceName}
                            onChange={(e) => setNewServiceName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="newServiceDescription" className="mt-2">
                        <Form.Label>{t("Service Description")}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newServiceDescription}
                            onChange={(e) => setNewServiceDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" onClick={handleAddService} className="mt-3">
                        {t("Add Service")}
                    </Button>
                </Form>
                <ListGroup className="mt-4">
                    {serviceList.map((service, index) => (
                        <ListGroup.Item key={index}>
                            {editingIndex === index ? (
                                <>
                                    <Form.Control
                                        type="text"
                                        value={editingServiceName}
                                        onChange={(e) => setEditingServiceName(e.target.value)}
                                        className="mb-2"
                                    />
                                    <Form.Control
                                        type="text"
                                        value={editingServiceDescription}
                                        onChange={(e) => setEditingServiceDescription(e.target.value)}
                                    />
                                </>
                            ) : (
                                <>
                                    <span><b>{service.serviceName}</b></span>
                                    <br />
                                    <span>{service.serviceDescription}</span>
                                </>
                            )}
                            <div className="float-end">
                                {editingIndex === index ? (
                                    <Button variant="success" size="sm" onClick={handleUpdateService} className="me-2">
                                        {t("Save")}
                                    </Button>
                                ) : (
                                    <Button variant="warning" size="sm" onClick={() => handleEditService(index)} className="me-2">
                                        {t("Edit")}
                                    </Button>
                                )}
                                <Button variant="danger" size="sm" onClick={() => handleDeleteService(index)}>
                                    {t("Delete")}
                                </Button>
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ServiceListModal;
