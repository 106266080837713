import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Table, Button, Card, Modal, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthGetRequest, AuthPostRequest } from '../Backend/APIMiddleware';
import { Form } from 'react-bootstrap'
export const CustomerDetails = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerID = queryParams.get('customerID');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [repairs, setRepairs] = useState([]);
    const [layaways, setLayaways] = useState([]);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { credentials, currentUser } = props;

    useEffect(() => {
        // Fetch customer data, purchases, repairs, and layaways using AuthGetRequest
        const fetchCustomerData = async () => {
            setIsLoadingData(true)
            // Dummy data for testing
            const customerData = {
                firstName: 'John',
                lastName: 'Doe',
                creationDate: '2022-01-01',
                accountType: 'Customer',
                phone: '+1234567890',
                email: 'john.doe@example.com',
                emailPromotions: true,
                currentYear: 2024,
                yearSpend: 1200,
                yearSave: 300
            };
            //setCustomerData(customerData);

            const purchasesData = [
                { transactionDate: '2023-05-01', transactionID: '123', employeeName: 'Alice', totalItems: 3, totalAmount: 150 },
                { transactionDate: '2023-05-10', transactionID: '124', employeeName: 'Bob', totalItems: 1, totalAmount: 50 }
            ];
            //setPurchases(purchasesData);

            const repairsData = [
                { transactionDate: '2023-04-15', transactionID: '125', employeeName: 'Charlie', totalItems: 1, totalAmount: 200, balanceDue: 0, repairStatus: 'Completed', completionDate: '2023-04-20' }
            ];
            //setRepairs(repairsData);

            const layawaysData = [
                { transactionDate: '2023-03-01', transactionID: '126', employeeName: 'David', totalItems: 2, totalAmount: 500, contractStartDate: '2023-03-01', timeRemainingToPay: '30 days', paidFullDate: '2023-04-01' }
            ];
            //setLayaways(layawaysData);

            AuthGetRequest(`/v1/accounts?customerID=${customerID}`).then((customerResponse)=>{
                setCustomerData(customerResponse.data);
            }).then(()=>{
                setIsLoadingData(false)
            }).catch((error)=>{
                console.error(error)

            })

            // const purchasesResponse = await AuthGetRequest(`/api/customers/${customerID}/purchases`);
            // setPurchases(purchasesResponse);

            // const repairsResponse = await AuthGetRequest(`/api/customers/${customerID}/repairs`);
            // setRepairs(repairsResponse);

            // const layawaysResponse = await AuthGetRequest(`/api/customers/${customerID}/layaways`);
            // setLayaways(layawaysResponse);
        };

        fetchCustomerData();
    }, [customerID]);

    const handleResetPassword = async () => {
        setIsResettingPassword(true);
        try {
            // Add your password reset API call here
            await AuthPostRequest('/api/reset-password', { customerID });
            alert('Password reset successfully');
        } catch (error) {
            console.error('Error resetting password:', error);
        } finally {
            setIsResettingPassword(false);
        }
    };

    const renderTable = (data, columns, columnKeys) => (
        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{t(column)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {columnKeys.map((key, colIndex) => (
                            <td key={colIndex}>{columnKeys[colIndex] ==='totalAmount'? '$':''}{row[key]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );

    // Usage within the component
    const purchaseColumns = ['Transaction Date', 'Transaction #', 'Employee', 'Total Items', 'Total'];
    const purchaseColumnKeys = ['transactionDate', 'transactionID', 'employeeName', 'totalItems', 'totalAmount'];

    const repairColumns = ['Transaction Date', 'Transaction #', 'Employee', 'Total Items', 'Total', 'Balance Due', 'Repair Status', 'Completion Date'];
    const repairColumnKeys = ['transactionDate', 'transactionID', 'employeeName', 'totalItems', 'totalAmount', 'balanceDue', 'repairStatus', 'completionDate'];

    const layawayColumns = ['Transaction Date', 'Transaction #', 'Employee', 'Total Items', 'Total', 'Contract Start Date', 'Time Remaining to Pay', 'Paid Full Date'];
    const layawayColumnKeys = ['transactionDate', 'transactionID', 'employeeName', 'totalItems', 'totalAmount', 'contractStartDate', 'timeRemainingToPay', 'paidFullDate'];


    return (
        <Container fluid>

            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h4>{customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}</h4>
                        </Col>
                        {credentials.isEmployee && (
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => setShowModal(true)}>{t('Reset Password')}</Button>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <Card.Text>
                                <strong>{t('Account name')}:</strong> {customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}<br />
                                <strong>{t('Account Type')}:</strong> {(customerData.isBusiness ? (<>{t('Business')}</>) : (<>{t('Customer')}</>))}<br />
                                <strong>{t('Phone Number')}:</strong> {customerData.phone}<br />
                                <strong>{t('Email')}:</strong> {customerData.email || 'Not on File'}<br />
                                <Form.Check
                                    type="switch"
                                    label={<strong>{t('Email Promotions')}</strong>}
                                    disabled={!customerData.emailPromotions && customerID !== currentUser.userId}
                                    checked={customerData.emailPromotions}
                                    onChange={() => { console.log("disabled") }}
                                />
                            </Card.Text>
                            {credentials.userID}
                        </Col>
                        <Col md={4}>
                            <Card.Text>
                                <strong>{t('Total Spend')} {customerData.currentYear}:</strong> ${customerData.totalYearSpend}<br />
                                <strong>{t('Total Savings')} {customerData.currentYear}:</strong> ${customerData.totalYearSpend}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Tabs defaultActiveKey="purchases" id="customer-details-tabs" className="mb-3">
                <Tab eventKey="purchases" title={t('Purchases')}>
                    {renderTable(purchases, purchaseColumns, purchaseColumnKeys)}
                </Tab>
                <Tab eventKey="repairs" title={t('Repairs')}>
                    {renderTable(repairs, repairColumns, repairColumnKeys)}
                </Tab>
                <Tab eventKey="layaways" title={t('Layaway Contracts')}>
                    {renderTable(layaways, layawayColumns, layawayColumnKeys)}
                </Tab>
            </Tabs>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Reset Password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isResettingPassword ? (
                        <Spinner animation="border" />
                    ) : (
                        <p>{t('Are you sure you want to reset the password for this customer?')}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>{t('Cancel')}</Button>
                    {/* <Button variant="primary" onClick={handleResetPassword} disabled={isResettingPassword}> */}
                    <Button variant="primary" onClick={handleResetPassword} disabled>

                        {isResettingPassword ? <Spinner as="span" animation="border" size="sm" /> : t('Reset Password')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isLoadingData} centered>
                <Modal.Body >
                    <div className='centered'>
                        <h3>{t("Getting Account Information")}</h3>
                    </div>
                    <div className='centered'><Spinner size='xl'/></div>
                    
                </Modal.Body>
            </Modal>
        </Container>
    );
};
