import React from 'react';
import { useDetailsPanel } from './DetailsPanelContext';

const DetailsPanel = () => {
  const { detailsPanelComponent } = useDetailsPanel();

  //if (!detailsPanelComponent) return null;

  return (
    <div className={`details-panel ${detailsPanelComponent ? "open" : "close"}`}>
      {detailsPanelComponent}
    </div>
  );
};

export default DetailsPanel;
