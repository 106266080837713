import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; // Import styles for react-quill
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const AboutContactUsModal = ({ show, handleClose, aboutText, contactText, saveAboutUs, saveContactUs }) => {
    const { t } = useTranslation();

    const [aboutUs, setAboutUs] = useState(aboutText);
    const [contactUs, setContactUs] = useState(contactText);

    useEffect(() => {
        setAboutUs(aboutText);
        setContactUs(contactText);
    }, [aboutText, contactText]);

    const handleSave = () => {
        const sanitizedAboutUs = DOMPurify.sanitize(aboutUs);
        const sanitizedContactUs = DOMPurify.sanitize(contactUs);
        saveAboutUs(sanitizedAboutUs);
        saveContactUs(sanitizedContactUs);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>{t("Edit About Us and Contact Us")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="aboutUsText">
                        <Form.Label><strong>{t("About Us")}</strong></Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={aboutUs}
                            onChange={setAboutUs}
                        />
                    </Form.Group>
                    <Form.Group controlId="contactUsText" className="mt-3">
                        <Form.Label><strong>{t("Contact Us")}</strong></Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={contactUs}
                            onChange={setContactUs}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t("Close")}
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    {t("Save Changes")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AboutContactUsModal;
