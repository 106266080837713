import { AuthGetRequest, AuthPostRequest } from "../APIMiddleware";

export async function NewAccount (Account){
    try{
        let response = await AuthPostRequest(`/v1/accounts/create`,Account);
        console.log(response);
        return response
    }
    catch(error){
        console.log(error);
        throw error
    }
}
export async function getPagedAccounts(pageSize, pageNumber){
    try {
        // Await directly for the promise to resolve and get the response
        const response = await AuthGetRequest(`/v1/accounts/list?page=${pageNumber}&size=${pageSize}`);
        console.log(response.data);
        // Return the data directly
        return response.data;
    } catch(error) {
        // Handle any errors that occur during the fetch
        console.error("Error fetching paged accounts:", error);
        throw error; // Rethrow the error if you want calling function to handle it
    }
}
