import { fetchAuthSession } from '@aws-amplify/auth';
import axios from 'axios';
const BASEURLAWS = `https://fsfpbk56je.execute-api.us-east-1.amazonaws.com/Test`
const BASEURL = `https://api.gemrxdoctors.com`
export async function getIdToken() {
  try {
    const session = await fetchAuthSession();
    return session.tokens.idToken.toString();
  } catch (error) {
    console.error("Error getting ID token", error);
    throw error
  }
}

export async function AuthGetRequest(endpoint) {
  const token = await getIdToken();
  if (!token) {
    return; // or handle the case where there's no token available
  }
  try {
    const response = await axios.get(`${BASEURLAWS}${endpoint}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response
  } catch (error) {
    console.error("Error fetching data", error);
    throw error
  }
}
export async function AuthPostRequest(endpoint, body) {
  const token = await getIdToken();
  if (!token) {
    return; // or handle the case where there's no token available
  }
  try {
    const response = await axios.post(`${BASEURLAWS}${endpoint}`, body, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response;
  } catch (error) {
    console.error("Error posting data", error);
    throw error;
  }
}
export async function AuthDeleteRequest(endpoint) {
  const token = await getIdToken();
  if (!token) {
    return; // or handle the case where there's no token available
  }
  try {
    const response = await axios.delete(`${BASEURLAWS}${endpoint}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    });
    return response
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
}
export async function UnAuthGetRequest(endpoint) {
  try{
      const response = await axios.get(`${BASEURLAWS}${endpoint}`, {
    headers: {

    }
  });
  return response
  }
  catch(error){
    console.error(error)
    throw error
  }
}
export async function AuthFileUpload(endpoint, body){
  const token = await getIdToken();
  if (!token) {
    return; // or handle the case where there's no token available
  }
  try {
    const response = await axios.post(`${BASEURLAWS}${endpoint}`, body, {
      headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'multipart/form-data' // Ensure Content-Type is set correctly
      }
    });
    return response;
  } catch (error) {
    console.error("Error posting data", error);
    throw error;
  }
}

