import React, { useState, createContext, useContext } from 'react';

const DetailsPanelContext = createContext();

export const useDetailsPanel = () => useContext(DetailsPanelContext);

export const DetailsPanelProvider = ({ children }) => {
  const [detailsPanelComponent, setDetailsPanelComponent] = useState(null);

  const showDetailsPanel = (component) => {
    setDetailsPanelComponent(component);
  };

  const hideDetailsPanel = () => {
    setDetailsPanelComponent(null);
  };

  return (
    <DetailsPanelContext.Provider value={{ detailsPanelComponent, showDetailsPanel, hideDetailsPanel }}>
      {children}
    </DetailsPanelContext.Provider>
  );
};
