import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaPlus, FaTimesCircle } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HoursPopupComponent from './HoursEditor';
import { AuthDeleteRequest, AuthPostRequest, UnAuthGetRequest } from '../Backend/APIMiddleware';
import AboutContactUsModal from './AboutAndContactUsEditor';
import { HomeScreen, getCurrentStatus } from '../Home';
import ServiceListModal from './ServicesListEditor';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

export function PublicHomePageEditor() {
    const { t } = useTranslation();
    const [triggerHoursRefresh, setTrigger] = useState(true)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editmode = queryParams.get('view');

    const [hoursStatus, setHoursStatus] = useState([]);
    const [AboutUsDialoge, setAbout] = useState('We have been serving people in the city of Auburndale for the past 24 years and in Florida for the past 38 years.');
    const [ContactUs, setContactUs] = useState(`You can contact us at +1(863)-604-9085`);
    const [showServiceListModal, setShowServiceListModal] = useState(false);

    const [storeStatus, setStoreStatus] = useState('');
    const [servicesList, setServicesList] = useState([]);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showAboutContactModal, setShowAboutContactModal] = useState(false);
    const [galleryImageList, setGalleryImages] = useState([
        // ... other images
    ]);
    const [defaultHours, setDefaultHours] = useState({
        Monday: { closed: true, open: '', close: '' },
        Tuesday: { closed: true, open: '', close: '' },
        Wednesday: { closed: true, open: '', close: '' },
        Thursday: { closed: true, open: '', close: '' },
        Friday: { closed: false, open: '11:00', close: '15:00' },
        Saturday: { closed: false, open: '09:00', close: '16:00' },
        Sunday: { closed: false, open: '09:00', close: '16:00' }
    });
    const [specialHours, setSpecialHours] = useState([]);

    const [showImageUploadModal, setShowImageUploadModal] = useState(false);
    const [newImageFile, setNewImageFile] = useState(null);
    const [newImageURL, setNewImageURL] = useState('');
    const [newImageID, setNewImageID] = useState('');
    const [description, setDescription] = useState('');
    const [itemNumber, setItemNumber] = useState('');
    const [tags, setTags] = useState(['']);
    const [uploading, setUploading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [floatingSpinner, setFloatingSpinner] = useState(false);

    const handleSaveHours = async (day, hours) => {
        setTrigger(true)
    };

    const handleAddSpecialHour = () => {
        console.log('Add special hour');
    };

    const handleEditSpecialHour = (index) => {
        console.log('Edit special hour at index:', index);
    };

    const handleDeleteSpecialHour = (index) => {
        console.log('Delete special hour at index:', index);
    };

    const handleShowModal = (index) => {
        setActiveImageIndex(index);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handlePrevImage = () => {
        setActiveImageIndex((prevIndex) => (prevIndex - 1 + galleryImageList.length) % galleryImageList.length);
    };

    const handleNextImage = () => {
        setActiveImageIndex((prevIndex) => (prevIndex + 1) % galleryImageList.length);
    };

    const handleShowAboutContactModal = () => {
        setShowAboutContactModal(true);
    };

    const handleCloseAboutContactModal = () => {
        setShowAboutContactModal(false);
    };

    const saveAboutUs = async (aboutUsText) => {
        try {
            const response = await AuthPostRequest('/v1/public/about-us', JSON.stringify(aboutUsText));

            if (response.status === 200) {
                setAbout(aboutUsText);
                console.log('About Us updated successfully');
            } else {
                console.error('Failed to update About Us');
            }
        } catch (error) {
            console.error('Error updating About Us:', error);
        }
    };

    const handleShowServiceListModal = () => {
        setShowServiceListModal(true);
    };

    const handleCloseServiceListModal = () => {
        setShowServiceListModal(false);
    };

    const addService = async (serviceName, serviceDescription) => {
        const newService = {
            serviceName: serviceName,
            serviceDescription: serviceDescription
        };
        try {
            const response = await AuthPostRequest('/v1/public/services', JSON.stringify(newService));

            if (response.status === 200) {
                newService.contentID = response.data.id;
                newService.contentType = 'Service';
                setServicesList((prevList) => [...prevList, newService]);
                console.log('Service added successfully');
            } else {
                console.error('Failed to add service');
            }
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const updateService = async (index, serviceName, serviceDescription) => {
        const updatedService = {
            contentID: servicesList[index].contentID,
            serviceName: serviceName,
            serviceDescription: serviceDescription
        };
        try {
            const response = await AuthPostRequest(`/v1/public/services/${servicesList[index].contentID}`, JSON.stringify(updatedService));
            console.log(response);
            if (response.status === 200) {
                setServicesList((prevList) => {
                    const newList = [...prevList];
                    newList[index] = updatedService;
                    setServicesList(newList);
                    return newList;
                });
                console.log('Service updated successfully');
            } else {
                console.error('Failed to update service');
            }
        } catch (error) {
            console.error('Error updating service:', error);
        }
    };

    const deleteService = async (index) => {
        try {
            const response = await AuthDeleteRequest(`/v1/public/services/${servicesList[index].contentID}`);

            if (response.status === 200) {
                setServicesList((prevList) => prevList.filter((_, i) => i !== index));
                console.log('Service deleted successfully');
                return true;
            } else {
                console.error('Failed to delete service');
            }
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const saveContactUs = async (contactUsText) => {
        try {
            const response = await AuthPostRequest('/v1/public/contact-us', JSON.stringify(contactUsText));
            if (response.status === 200) {
                setContactUs(contactUsText);
                console.log('Contact Us updated successfully');
            } else {
                console.error('Failed to update Contact Us');
            }
        } catch (error) {
            console.error('Error updating Contact Us:', error);
        }
    };

    const handleDeleteImage = async (index) => {
        setFloatingSpinner(true);

        const imageID = galleryImageList[index].contentID;
        try {
            const response = await AuthDeleteRequest(`/v1/public/gallery/${imageID}`);
            if (response.status === 200) {
                setGalleryImages((prevList) => prevList.filter((_, i) => i !== index));
                console.log('Image deleted successfully');
            } else {
                console.error('Failed to delete image');
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
        setFloatingSpinner(false);

    };

    const handleImageUpload = async (base64Image, fileName) => {
        setUploading(true);
        setFloatingSpinner(true);
        const imageData = {
            image: base64Image,
            fileName: fileName
        };

        try {
            const response = await AuthPostRequest(`/v1/public/gallery`, JSON.stringify(imageData));
            const { url, imageID } = response.data;
            setNewImageURL(url);
            setNewImageID(imageID);
            setUploading(false);
            setShowImageUploadModal(true);
            setFloatingSpinner(false);
        } catch (error) {
            console.error('Error uploading image:', error);
            setUploading(false);
            setFloatingSpinner(false);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
                setNewImageFile(file);
                handleImageUpload(base64String, file.name);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCancelUpload = async () => {
        setShowImageUploadModal(false);

        setFloatingSpinner(true);
        try {
            await AuthDeleteRequest(`/v1/public/gallery/${newImageID}`);
            console.log('Image deleted successfully');
        } catch (error) {
            console.error('Error deleting image:', error);
        }
        setFloatingSpinner(false);

        setNewImageFile(null);
        setNewImageURL('');
        setNewImageID('');
        setDescription('');
        setItemNumber('');
        setTags(['']);
    };

    const saveImageDetails = async () => {
        setSaving(true);
        const newImage = {
            contentID: newImageID,
            imageURL: newImageURL,
            metaData: '',
            description: description,
            itemNumber: itemNumber,
            tags: tags.filter((tag) => tag !== ''),
        };
        try {
            const response = await AuthPostRequest('/v1/public/upload-sample', JSON.stringify(newImage));
            if (response.status === 200) {
                setGalleryImages([...galleryImageList, newImage]);
                console.log('Image details saved successfully');
                setShowImageUploadModal(false);
            } else {
                console.error('Failed to save image details');
            }
        } catch (error) {
            console.error('Error saving image details:', error);
        } finally {
            setSaving(false);
        }
    };

    useEffect(() => {
        UnAuthGetRequest(`/v1/public/about-us`).then((response) => {
            console.log(response);
            setAbout(response.data.response);
        }).catch((error) => {
            setAbout("Failed to get About Us content");
        });
        UnAuthGetRequest(`/v1/public/contact-us`).then((response) => {
            console.log(response);
            if (!response.data) {
                console.log("No data");
            }
            setContactUs(response.data.response);
        }).catch((error) => {
            setContactUs("No contact info available. Check back later.");
        });
        UnAuthGetRequest(`/v1/public/services`).then((response) => {
            console.log(response);
            setServicesList(response.data);
        }).catch((error) => {
            setServicesList([{ serviceName: 'Failed to get services List', serviceDescription: 'Please check back in a while.' }]);
        });
        UnAuthGetRequest(`/v1/public/gallery`).then((response) => {
            setGalleryImages(response.data)
        }).catch((error) => {
            console.error("Failed to get gallery images.")
        })
    }, []);

    useEffect(() => {
        const fetchLiveHours = async () => {
            try {
                const response = await fetch('https://fsfpbk56je.execute-api.us-east-1.amazonaws.com/Test/v1/public/hours');
                const schedule = await response.json();

                const adjustedSchedule = schedule.reduce((acc, day) => {
                    acc[day.weekday] = {
                        closed: day.closed,
                        open: day.hours[0].split(" - ")[0] || '',
                        close: day.hours[0].split(" - ")[1] || ''
                    };
                    return acc;
                }, {});

                setDefaultHours(adjustedSchedule);
                setStoreStatus(getCurrentStatus(schedule));
                setHoursStatus(schedule);
            } catch (error) {
                console.error('Error fetching live hours:', error);
            }
        };
        const fetchSpecialHours = async () => {
            try {
                const response = await fetch('https://fsfpbk56je.execute-api.us-east-1.amazonaws.com/Test/v1/public/hours/special-hours-list');
                const specialHoursData = await response.json();

                const adjustedSpecialHours = specialHoursData.map((specialHour) => ({
                    date: specialHour.date,
                    open: specialHour.hours[0].split(' - ')[0],
                    close: specialHour.hours[0].split(' - ')[1]
                }));

                setSpecialHours(adjustedSpecialHours);
            } catch (error) {
                console.error('Error fetching special hours:', error);
            }
        };
        if (triggerHoursRefresh) {
            fetchSpecialHours();
            fetchLiveHours();
            setTrigger(false)
        }
    }, [triggerHoursRefresh]);

    const styles = {
        navBar: {
            marginBottom: '20px',
        },
        footer: {
            marginTop: '30px',
            backgroundColor: '#f8f9fa',
            padding: '10px',
            textAlign: 'center',
            fontSize: '0.9rem',
            borderTop: '1px solid #e7e7e7',
        },
        section: {
            marginBottom: '20px',
        },
        list: {
            listStyleType: 'none',
            paddingLeft: 0,
        },
        imageContainer: {
            position: 'relative',
            display: 'inline-block',
            margin: '5px',
        },
        deleteIcon: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            color: 'red',
            fontSize: '1.5em',
        },
        floatingSpinner: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            backgroundColor: 'rgba(42, 49, 57, 0.9)',
            padding: '100px',
            borderRadius: '10px',
            color: "white"
        },
    };

    const isSaveButtonDisabled = !description || tags.filter((tag) => tag !== '').length === 0;

    if (editmode === 'live') {
        return (
            <>
                <div className='centered'>
                    <h1>{t("Live Mode")}</h1>
                    <p></p>
                </div>
                <HomeScreen />
            </>
        );
    }

    return (
        <div className="container">
            <nav style={styles.navBar}>
                <ul className="nav nav-pills">
                    <li className="nav-item"><a className="nav-link" href="#about-us">{t("About Us")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#contact-us">{t("Contact Us")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#location">{t("Location")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#hours">{t("Hours")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#services">{t("Services")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#gallery">{t("Gallery")}</a></li>
                </ul>
            </nav>

            <section id="about-us" style={styles.section}>
                <h2>{t("About Us")} <FaEdit onClick={handleShowAboutContactModal} /></h2>
                <p dangerouslySetInnerHTML={{ __html: AboutUsDialoge }}></p>
            </section>

            <section id="contact-us" style={styles.section}>
                <h2>{t("Contact Us")} <FaEdit onClick={handleShowAboutContactModal} /></h2>
                <p dangerouslySetInnerHTML={{ __html: ContactUs }}></p>
            </section>

            <section id="location" style={styles.section}>
                <h2>{t("Location")}</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.934444938318!2d-81.83070052452007!3d28.057026175983935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd6ad35fec1ced%3A0x49f354afe52b1c4!2sThe%20Jewelry%20Doctors%20of%20Auburndale%20Inc!5e0!3m2!1sen!2sus!4v1711059402452!5m2!1sen!2sus" style={{ width: "100%", height: "600px", border: "0", allowFullScreen: "", loading: "lazy", referrerPolicy: "no-referrer-when-downgrade" }}></iframe>
            </section>

            <section id="hours" style={styles.section}>
                <h2>{t("Hours")} <FaEdit onClick={() => setShowPopup(true)} /></h2>
                <p>{t("Current Status")}: {!storeStatus ? (<Spinner />) : t(storeStatus)}</p>
                {hoursStatus && (<ul style={styles.list}>
                    {hoursStatus.map((day, index) => (
                        <li key={index}>{`${t(day.weekday)}: ${t(day.hours[0])}`}</li>
                    ))}
                </ul>)}
            </section>

            <section id="services" style={styles.section}>
                <h2>{t("Services")} <FaEdit onClick={handleShowServiceListModal} /></h2>
                {servicesList.length === 0 && (<Spinner />)}
                <ul style={styles.list}>
                    {servicesList.map((service, index) => (
                        <li key={index}><b>{`${service.serviceName}: `}</b>{` ${service.serviceDescription}`}</li>
                    ))}
                </ul>
            </section>

            <section id='gallery' className="mt-4">
                <h2>{t("Gallery")} <FaPlus onClick={() => document.getElementById('fileInput').click()} /></h2>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/jpeg"
                    onChange={handleFileChange}
                />
                <div className="row">
                    {galleryImageList.map((image, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
                            <div style={styles.imageContainer}>
                                <img
                                    src={image.imageURL}
                                    alt={image.description}
                                    style={{ cursor: 'pointer', objectFit: 'cover', width: '100%', maxHeight: '200px' }}
                                    className="img-fluid"
                                    onClick={() => handleShowModal(index)}
                                />
                                <FaTimesCircle style={styles.deleteIcon} onClick={() => handleDeleteImage(index)} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Modal for viewing images */}
            {galleryImageList.length > 0 && (<Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
            <Modal.Header closeButton>
                        <Modal.Title>
                            {galleryImageList[activeImageIndex].description}
                            {galleryImageList[activeImageIndex].itemNumber && (
                                <> ({galleryImageList[activeImageIndex].itemNumber})</>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                        <img
                            src={galleryImageList[activeImageIndex].imageURL}
                            alt={galleryImageList[activeImageIndex].caption}
                            className="img-fluid"
                        />
                        <Stack direction="horizontal" gap={2} className="mt-3">
                            {galleryImageList[activeImageIndex].tags.map((tag, index) => (
                                <Badge key={index} bg="dark">{tag}</Badge>
                            ))}
                        </Stack>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePrevImage}>&lt; {t("Prev")}</Button>
                    <Button variant="secondary" onClick={handleNextImage}>{t("Next")} &gt;</Button>
                </Modal.Footer>
            </Modal>)}

            {/* Modal for uploading new images */}
            <Modal show={showImageUploadModal} onHide={handleCancelUpload} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Upload New Image")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploading ? (
                        <Spinner animation="border" />
                    ) : (
                        <>
                            <img
                                src={newImageURL}
                                alt={t("Preview")}
                                style={{ width: '100%', marginBottom: '10px' }}
                            />
                            <input
                                type="text"
                                placeholder={t("Description")}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder={t("Item Number")}
                                value={itemNumber}
                                onChange={(e) => setItemNumber(e.target.value)}
                            />
                            {tags.map((tag, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    placeholder={t("Tag")}
                                    value={tag}
                                    onChange={(e) => {
                                        const newTags = [...tags];
                                        newTags[index] = e.target.value;
                                        setTags(newTags);
                                    }}
                                />
                            ))}
                            <Button onClick={() => setTags([...tags, ''])}>{t("Add Tag")}</Button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelUpload}>{t("Close")}</Button>
                    <Button variant="primary" onClick={saveImageDetails} disabled={isSaveButtonDisabled}>
                        {saving ? <Spinner animation="border" size="sm" /> : t("Save")}
                    </Button>
                </Modal.Footer>
            </Modal>

            {floatingSpinner && (
                <div style={styles.floatingSpinner}>
                    <Spinner className='centered' animation="grow" variant='light' style={{
                        width: "5rem",
                        height: "5rem",
                    }} />
                    <br />
                    <div className='centered'>
                        <p><strong><strong>{t("Loading")}...</strong></strong></p>
                    </div>
                </div>
            )}

            {/* Popup for editing business hours */}
            <HoursPopupComponent
                show={showPopup}
                handleClose={() => setShowPopup(false)}
                defaultHours={defaultHours}
                specialHours={specialHours}
                saveHours={handleSaveHours}
                addSpecialHour={handleAddSpecialHour}
                editSpecialHour={handleEditSpecialHour}
                deleteSpecialHour={handleDeleteSpecialHour}
            />

            {/* Modal for editing About Us and Contact Us */}
            <AboutContactUsModal
                show={showAboutContactModal}
                handleClose={handleCloseAboutContactModal}
                aboutText={AboutUsDialoge}
                contactText={ContactUs}
                saveAboutUs={saveAboutUs}
                saveContactUs={saveContactUs}
            />

            {/* Modal for editing Services */}
            <ServiceListModal
                show={showServiceListModal}
                handleClose={handleCloseServiceListModal}
                services={servicesList}
                addService={addService}
                updateService={updateService}
                deleteService={deleteService}
            />
        </div>
    );
}
